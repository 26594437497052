@function vw($px) {
  @return ($px / 360) * 100vw;
}

.login {
  position: relative;
  background: #fff;
  /* stylelint-disable */
  -webkit-touch-callout: none; /* 系统默认菜单被禁用 */
  user-select: none; /* webkit浏览器 */
  user-select: none; /* 早期浏览器 */
  user-select: none; /* 火狐 */
  user-select: none; /* IE10 */
  user-select: none;
  /* stylelint-enable */
  .wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    transition: all 0.5s;
    .feedback {
      position: absolute;
      top: vw(12);
      right: vw(18);
      width: vw(24);
      height: vw(24);
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          height: vw(59);
          line-height: vw(59);
          margin-top: vw(72);
          font-family: 'Montserrat Alternates';
          font-weight: 900;
          font-size: vw(48);
          color: #ffcd22;
        }
        .subTitle {
          height: vw(19);
          font-family: 'Roboto';
          font-weight: 900;
          font-size: vw(16);
          line-height: vw(19);
          color: #ffcd22;
        }
        .desc {
          margin-top: vw(36);
          height: vw(24);
          font-family: 'Roboto';
          font-weight: 500;
          font-size: vw(20);
          line-height: vw(24);
          color: #ffffff;
        }
        .login {
          display: flex;
          justify-content: center;
          align-items: center;
          width: vw(264);
          height: vw(48);
          margin-top: auto;
          background: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(vw(12));
          border-radius: vw(24);
          box-sizing: border-box;
          .icon {
            width: vw(32);
            height: vw(32);
            margin-right: vw(16);
          }
          .text {
            font-family: 'Roboto';
            font-weight: 500;
            font-size: vw(14);
            color: #2f2f2f;
          }
        }
        .agreement {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          max-width: 80%;
          margin-top: vw(40);
          margin-bottom: vw(48);
          .checkbox {
            box-sizing: border-box;
            width: vw(16);
            height: vw(16);
            margin-right: vw(8);
            border: 2px solid #ffcd22;
            border-radius: 50%;
          }
          .checkboxSelected {
            box-sizing: border-box;
            width: vw(16);
            height: vw(16);
            margin-right: vw(8);
          }
          .text {
            flex: 1;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: vw(12);
            color: #ffffff;
            .link {
              color: #5eb1ff;
            }
          }
        }
      }
    }
  }
}
